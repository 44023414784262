import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import React from "react";
import {
  logoMKCs,
  logoMKEn,
  logoMPOCs,
  logoNGEuCs,
  logoNGEuDe,
  logoNGEuEn,
  logoNPO
} from "../../components/Images";
import Row from "../../components/Row";
import styled from "../../styling/styled";
import { theme } from "../../styling/theme";

const ColNews = styled.div`
  max-width: calc(100% / 3);
  flex-basis: calc(100% / 3);
  border-bottom: 1px solid #d7d7d7;
  ${[theme.media.maxM]} {
    max-width: 50%;
    flex-basis: 50%;
  }
  ${[theme.media.maxS]} {
    max-width: 100%;
    flex-basis: 100%;
  }
  &:nth-last-child(1) {
    border: none;
  }
  ${[theme.media.s]} {
    &:nth-last-child(-n+2) {
      border: none;
    }
  }
  ${[theme.media.m]} {
    &:nth-last-child(-n+3) {
      border: none;
    }
  }
`;

const Post = styled.div`
  max-width: 255px;
  padding-top: 52px;
  padding-bottom: 52px;
  ${[theme.media.maxM]} {
    max-width: 100%;
    padding-top: 30px;
    padding-bottom: 10px;
  }
`;

const PostHeading = styled.h3`
  margin-bottom: 0;
`;

const PostContent = styled.div`
  p {
    margin-bottom: 0;
    hyphens: auto;
  }

  a {
    color: #000952;
    word-break: break-all;
  }
`;

class Articles extends React.Component {
  getLogoNGEuSource = () => {
    const { intl } = this.props;

    switch (intl.locale) {
      case "cs":
        return logoNGEuCs;
      case "en":
        return logoNGEuEn;
      case "de":
        return logoNGEuDe;
      default:
        return logoNGEuEn;
    }
  };

  getLogoMKSource = () => {
    const { intl } = this.props;

    switch (intl.locale) {
      case "cs":
        return logoMKCs;
      case "en":
        return logoMKEn;
      default:
        return logoMKEn;
    }
  };
  render() {
    const logoNGEu = this.getLogoNGEuSource();
    const logoMK = this.getLogoMKSource();
    return (
      <>
        <Row spacing={15}>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.15.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.15.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.15.text" />
                <img src={logoNPO} alt="" />
                <img src={logoMK} alt="" />
                <img src={logoNGEu} alt="" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.14.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.14.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.14.text" />
                <img src={logoNPO} alt="" />
                <img src={logoMPOCs} alt="" />
                <img src={logoNGEu} alt="" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.13.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.13.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.13.text" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.12.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.12.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.12.text" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.11.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.11.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.11.text" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.10.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.10.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.10.text" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.9.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.9.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.9.text" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.8.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.8.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.8.text" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.7.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.7.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.7.text" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.6.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.6.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.6.text" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.5.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.5.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.5.text" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.4.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.4.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.4.text" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.3.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.3.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.3.text" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.2.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.2.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.2.text" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.1.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.1.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.1.text" />
              </PostContent>
            </Post>
          </ColNews>
          <ColNews>
            <Post>
              <PostHeading>
                <FormattedMessage id="partials.news.articles.0.title" />
              </PostHeading>
              <p>
                <FormattedHTMLMessage id="partials.news.articles.0.date" />
              </p>
              <PostContent>
                <FormattedHTMLMessage id="partials.news.articles.0.text" />
              </PostContent>
            </Post>
          </ColNews>
        </Row>
      </>
    );
  }
}

export default injectIntl(Articles);
