import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import FooterBackground from "../components/FooterBackground";
import {
  circleSmall,
  loader,
  waveHorizontalLarge,
  waveVerticalSmall
} from "../components/Images";
import Layout from "../components/Layout";
import CTA from "../partials/CTA";
import Articles from "../partials/news/Articles";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

const NewsContent = styled.div`
  padding-top: 120px;
  padding-bottom: 180px;
  background-size: 160px 17px, 160px 17px, 17px 17px, 17px 47px, 17px 17px,
    17px 17px, 30px 30px;
  background-image: url(${waveHorizontalLarge}), url(${waveHorizontalLarge}),
    url(${circleSmall}), url(${waveVerticalSmall}), url(${circleSmall}),
    url(${circleSmall}), url(${loader});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 277px) top 90px,
    right calc(${theme.container.background} - 320px) top 150px,
    left calc(${theme.container.background} - 87px) top 43%,
    left calc(${theme.container.background} - 127px) bottom 18%,
    left calc(${theme.container.background} + 18%) bottom 5%,
    right calc(${theme.container.background} - 100px) bottom 20%,
    right calc(${theme.container.background} + 20%) top 15%;

  ${[theme.media.mobileMenuVisible]} {
    padding-top: 75px;
    padding-bottom: 120px;
    min-height: 0;
  }
`;

class NewsPage extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Helmet
          title={
            intl.formatMessage({ id: "pages.news.title1" }) + " | Eledio.com"
          }
          meta={[
            {
              name: "description",
              content: intl.formatMessage({ id: "pages.news.description" })
            },
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.news.title1" })
            },
            {
              property: "og:description",
              content: intl.formatMessage({ id: "pages.news.description" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Layout>
          <NewsContent>
            <Container>
              <h1>
                <FormattedMessage id="pages.news.title1" />
              </h1>
              <Articles />
            </Container>
          </NewsContent>
          <CTA
            buttonLink="/contact/"
            buttonText={intl.formatMessage({ id: "pages.hardware.ctatext" })}
            claim={intl.formatMessage({ id: "pages.hardware.ctaclaim" })}
          />
          <FooterBackground />
        </Layout>
      </>
    );
  }
}

export default injectIntl(NewsPage);
